import React from 'react'
import Section from '../components/Section'
import ReturnToSiteLink from '../components/ReturnToSiteLink'
import Layout from '../components/Layout'
import Seo from '../components/Seo'
import { graphql } from 'gatsby'
import SectionBanner from '../components/SectionBannerSmall'
import parse, { domToReact } from 'html-react-parser'
import DecorativeLink from '../components/DecorativeLink'

function ContractVehicleTemplate({ data }) {
  const contractVehicle = data.contractVehiclesJson
  return (
    <Layout>
      <Seo
        title={contractVehicle.title}
        description={contractVehicle.overview}
        relPath={`/contract-vehicles/${contractVehicle.jsonId}/`}
      />
      <div className="page--contract-vehicle">
        <SectionBanner
          bgImage={`contract-vehicles/${contractVehicle.heroImage}`}
          title={contractVehicle.heading}
        />
        <Section id="contract-vehicle" indent={true} spacing={0} constrain>
          <div
            className={`contract-vehicle__details-container ${contractVehicle.jsonId}`}
          >
            <div className="vertical-line"></div>
            {contractVehicle.keypoints.map((keypoint, i) => {
              const html = `${keypoint.info}`

              const options = {
                replace: domNode => {
                  if (!domNode.attribs) return

                  if (domNode.name === 'a') {
                    return (
                      <>
                        <DecorativeLink href={domNode.attribs.href}>
                          {domToReact(domNode.children, options)}
                        </DecorativeLink>
                      </>
                    )
                  }
                },
              }

              return (
                <div className="key-point" key={i}>
                  <div className="title">
                    <h2>{keypoint.title}</h2>
                  </div>
                  <div className="info">{parse(html, options)}</div>
                </div>
              )
            })}
          </div>
          <ReturnToSiteLink
            location="/contract-vehicles/#contract-vehicles"
            position={'bottom'}
            value={'Back to Contract Vehicles'}
          ></ReturnToSiteLink>
        </Section>
      </div>
    </Layout>
  )
}

export const query = graphql`
  query ContractVehiclesTemplate($jsonId: String!) {
    contractVehiclesJson(jsonId: { eq: $jsonId }) {
      jsonId
      keypoints {
        info
        title
      }
      heading
      heroImage
      heroImageAlt
      logo
      logoAlt
      overview
      title
    }
  }
`

export default ContractVehicleTemplate
